@import "variables";

.form-title{
  font-size: 24px;
  font-weight: 400;
  color:$mainBlack;
}
.form-subtitle{
  margin-top: 15px;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: $textGrey;
  margin-bottom: 15px;
}

.two-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    width: 45%;
  }
}

.form-wrapper{
  max-width: 520px;
  margin: 0 auto;
  text-align: left;
}
.form-field{
  display: block;
  width: 100%;
  margin-top: 20px;
  &:first-of-type{
    margin-top: 0;
  }
}
.form-input-name{
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: left;
}
.form-input-subname{
  text-align: left;
  margin-bottom: 30px;
  color: $placeholderGrey;
}

.form-group{
  position: relative;
  text-align: left;
  margin-bottom: 25px;
  &:last-of-type{
    margin-bottom: 0;
  }
  &__title{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  &__item .row{
    margin-top: 10px;
    &:first-of-type{
      margin-top: 0;
    }
  }
}

.form-group__item{
  color: $placeholderGrey;
}
.form-group .row{
  margin-top: 10px;
  &:first-of-type{
    margin-top: 0;
  }
}

.form-step{
  font-size: 24px;
  font-weight: 400;
  color: $textGrey;
  margin-bottom: 40px;
}

//select for adding products
.standard-select-label {
  position: relative;
  height: 35px;
  display: inline-block;

  select {
    position: relative;
    line-height: 21px;
    height: 35px;
    background: none;
    padding: 6px;
    padding-right: 20px;
    padding-left: 10px;
    border-radius: 5px;
    border:1px solid $mainGrey;
    outline:none;
    color: $textGrey;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  &:after {
    content: '';
    background-image: url('/assets/images/arrows-up-down.png');
    width: 9px;
    height: 15px;
    display: block;
    position: absolute;
    top: 10px;
    right: 6px;
  }
}

.price-input {

  &:before {
    content: '$';
    position: absolute;
    margin: 8px 0 0 10px;
  }

  input {
    padding-left: 20px !important;

    &::placeholder {
      text-align: center;
      font-size: 14px;
      color: $textLightGrey;
    }
  }
}

// disable cross button for ie
input::-ms-clear {
  display: none;
}

.mat-input-subtext {
  font-size: 12px;
  color: $textGrey;
  text-align: right;
  width: 100%;
  display: block;
  margin-top: -15px;
}

input:disabled {
  color: $textGrey;
  border-color: $textGrey;
  border-width: 1px;
}
