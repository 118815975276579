@font-face {
  font-family: 'DecimaNovaPro';
  src: url('/assets/fonts/DecimaNovaPro/DecimaNovaPro.otf') format('opentype');
}

@font-face {
  font-family: 'DecimaNovaProBold';
  src: url('/assets/fonts/DecimaNovaPro/DecimaNovaPro-Bold.otf') format('opentype');
}


* {
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); 
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-family: 'Inter', sans-serif !important;
}