@import "variables";

.main-wrapper{
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
}

.main-wrapper-top {
  margin-top: 30px;
  // margin-top: 110px;
}

.link {
  cursor: pointer;
  outline: none;
  color: $mainBlue;

  &:hover {
    opacity: 0.7;
  }

  &.red {
    color: $mainRed;
  }
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.tooltip-object {
  border-bottom: dashed 1px $mainBlack;
  cursor: help;
  width: fit-content;
}

.spinner-container-big {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1300;
  background-color: $spinnerBck;
  display: flex;
  justify-content: center;
  align-items: center;
}
