/* You can add global styles to this file, and also import other style files */
@import "~dragula/dist/dragula.css";
@import "~videogular2/fonts/videogular.css";

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import "styles/variables";
@import "styles/fonts";
@import "styles/core";
@import "styles/button";
@import "styles/form";
@import "styles/reset";

[hidden]{
  display: none !important;
}

.cdk-overlay-pane.action-dialog {
  position: relative!important;
}
.cdk-overlay-pane.action-dialog h3.title {
  margin-top: 10px;
}
